import React from "react"

import Layout from "../components/layout"
import SEO from "../components/seo"
import MessagePage from "../components/messagePage"

const MessageReceivedPage: React.FC = () => {
  return (
    <Layout>
      <SEO title="Message Received" />
      <MessagePage title={"Message Received"}>
        Thank you for contacting me. I will get back to you ASAP!
      </MessagePage>
    </Layout>
  )
}

export default MessageReceivedPage
